import React from 'react';
import ReactDOM from 'react-dom';
import ConsentBroker from './components/consent-broker';
import Visitor from './visitor';
import {
  OPT_OUT_CATS,
  PRIVACY_POLICY_URL,
  RESELLER_PRIVACY_POLICY_URL,
  RTL_MARKETS
} from './config';

export function render(props, element) {
  const {
    visitor,
    i18n = window.I18N_DATA,
    showBanner = !window.ux?.privacy?.invokePrivacyManagerFlag,
    showManager = !showBanner
  } = props;

  const privateLabelId = window?.ux?.data?.privateLabelId || window.ux?.eldorado?.data?.privateLabelId || 1;
  const isReseller = privateLabelId !== 1;

  const privacyPolicyParams = `?id=privacy&plid=${privateLabelId}`;
  const defaultPrivacyPolicyUrl = isReseller ? (RESELLER_PRIVACY_POLICY_URL + privacyPolicyParams) : PRIVACY_POLICY_URL;

  let privacyPolicyUrl = defaultPrivacyPolicyUrl;
  if (window?.ux?.privacy?.privacyPolicyUrl) {
    privacyPolicyUrl = window?.ux?.privacy?.privacyPolicyUrl;
  } else if (window?.ux?.data?.urls?.privacy?.href) {
    privacyPolicyUrl = window?.ux?.data?.urls?.privacy?.href;
  } else if (window?.ux?.eldorado?.data?.urls?.privacy?.href) {
    privacyPolicyUrl = window?.ux?.eldorado?.data?.urls?.privacy?.href;
  } else if (process.env.NODE_ENV !== 'production' && isReseller) {
    console.warn('Consent manager falling back to default privacy policy url behaviors');
  }


  const banner = visitor.startVisit();

  // From now on, all DOM manipulation should be handle by React!
  ReactDOM.render(
    // React with portal for synthetic event binding
    ReactDOM.createPortal(
      <ConsentBroker
        visitor={ visitor }
        i18n={ i18n }
        privacyPolicyUrl={ privacyPolicyUrl }
        showBanner={ showBanner }
        showManager={ showManager }
        banner={ banner } />,
      element.shadowRoot
    ),
    element
  );
}

export function prepareContext(context) {
  //
  // Setup public facing API
  //
  context.ux = context.ux || {};
  context.ux.privacy = context.ux.privacy || {};
}

/**
 * Create div with id='gtm-privacy' and attach shadow root inside created div
 *
 * @param {Visitor} visitor Page viewer for which consents are updating
 * @param {Window} context Host window context
 * @returns {Node} root element
 */
export async function prepareShadowRoot(visitor, context) { // eslint-disable-line complexity, max-statements
  const lang = visitor.getLanguage();
  const dir = RTL_MARKETS.includes(lang) ? 'rtl' : 'ltr';
  const doc = context.document;

  let root;
  try {
    root = doc.getElementById('gtm_privacy');
    const appendToBody = !root;
    if (appendToBody) {
      root = doc.createElement('div');
      root.setAttribute('id', 'gtm_privacy');
    }

    root.setAttribute('dir', dir);
    root.attachShadow({ mode: 'open' });

    if (appendToBody) {
      doc.body.appendChild(root);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  await injectStyles(root, context);

  return root;
}

/**
 * Injects the expected *.css file into
 * `root.shadowRoot` for proper visual appearance
 *
 * @param  {Element} root Host element with shadowRoot
 * @param {Window} context Host window context
 * @returns {Promise<void>} promise
 */
export function injectStyles(root, context) {
  const doc = context.document;

  return new Promise((resolve, reject) => {
    try {
      const css = context.ux?.privacy?.css;
      if (css) {
        const link = doc.createElement('link');
        link.href = css;
        link.rel = 'stylesheet';
        link.onload = () => resolve();
        link.onerror = (err) => reject(err);
        root.shadowRoot.appendChild(link);
      } else {
        resolve();
      }
    } catch (e) {
      reject(e);
    }
  });
}

export async function init(props = {}) {
  prepareContext(window);

  //
  // Create a visitor if none provided
  //
  if (!props.visitor) {
    props.visitor = new Visitor({
      url: new URL(window.location.href),
      optOutCats: OPT_OUT_CATS,
      context: window
    });
  }

  const element = await prepareShadowRoot(props.visitor, window);
  render(props, element);
}
